//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FeaturedIn',
  data() {
    return {
      logos: [
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-menshealth-logo-001.png',
          alt: 'Men\'s Health Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-pcmag-logo-001.png',
          alt: 'PC Mag Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-forbes-logo-001.png',
          alt: 'Forbes Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-cnn-logo-002.png',
          alt: 'CNN Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-time-logo-001.png',
          alt: 'Time Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-esquire-logo-002.png',
          alt: 'Esquire Logo',
        },
      ],
      lastRowLogos: [
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-womenshealth-logo-003.png',
          class: 'womens-health',
          alt: 'Women\'s Health Logo',
        },
        {
          source: 'https://cdn3.joinfightcamp.com/100x100/filters:format(webp)/v4/fc-package-insider-logo.png',
          class: 'insider',
          alt: 'Insider Logo',
        },
      ],
    };
  },
};
